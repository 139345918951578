import Bugsnag from '@bugsnag/js';
import type { InternalAxiosRequestConfig } from 'axios';

export const reportNetworkErrorToBugsnag = (error: any) => {
  Bugsnag.notify(error, (event) => {
    const errorHeaders = {
      ...error.config.headers,
      ...{ 'X-Authorization': 'REDACTED', Authorization: 'REDACTED' }
    };

    event.addMetadata('XHRRequest', {
      url: error.config.url,
      method: error.config.method,
      headers: errorHeaders
    });

    event.addMetadata('XHRResponse', {
      status: error.response.status,
      statusText: error.response.statusText,
      headers: error.response.headers
    });
  });
};

export const reportTimeoutErrorToBugsnag = (error: any) => {
  Bugsnag.notify(error, (event) => {
    const errorHeaders = {
      ...error.config.headers,
      ...{ 'X-Authorization': 'REDACTED', Authorization: 'REDACTED' }
    };

    let connectionType = 'feature not supported by browser.';
    const connection = navigator.connection;
    if (connection && connection.effectiveType) {
      connectionType = connection.effectiveType;
    }

    event.addMetadata('XHRRequest', {
      url: error.config.url,
      method: error.config.method,
      headers: errorHeaders,
      timeout: error.config.timeout,
      navigatorOnline: window.navigator.onLine,
      connectionType
    });
  });
};

export const leaveBugsnagBreadcrumbs = (
  config: InternalAxiosRequestConfig,
  status: number
) => {
  const metadata = {
    status: status,
    request: `${config.method ? config.method.toUpperCase() : 'UNKNOWN METHOD'} ${config.url}`
  };

  Bugsnag.leaveBreadcrumb('XMLHttpRequest succeeded', metadata, 'request');
};
