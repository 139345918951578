export class MessageEventData {
  public static readonly SEPARATOR = '|';

  constructor(
    private readonly _type: string,
    private readonly _uuid: string,
    private _data: any = '',
    private readonly _fromPath: string = '',
    private readonly _origin: string = ''
  ) {}

  get type(): string {
    return this._type;
  }

  get uuid(): string {
    return this._uuid;
  }

  get origin(): string {
    return this._origin;
  }

  get data(): any {
    return this._data;
  }

  set data(data: any) {
    this._data = data;
  }

  get fromPath(): string {
    return this._fromPath;
  }

  public static factory(payload: string): MessageEventData {
    const payloadParts = payload.split(MessageEventData.SEPARATOR);
    const origin = payloadParts.shift();

    if (payloadParts.length <= 1) {
      throw new Error('Invalid payload. Missing UUID');
    }

    let data;
    try {
      data = JSON.parse(payloadParts[2]);
    } catch (e) {
      data = payloadParts[2];
    }

    return new MessageEventData(
      payloadParts[0],
      payloadParts[1],
      data,
      payloadParts[3],
      origin
    );
  }

  public toString(): string {
    const formattedData =
      typeof this.data === 'object' ? JSON.stringify(this.data) : this.data;

    return [this.type, this.uuid, formattedData, this.fromPath]
      .filter((val) => val !== '')
      .join(MessageEventData.SEPARATOR);
  }

  public clone(): MessageEventData {
    return new MessageEventData(
      this.type,
      this.uuid,
      '',
      this.fromPath,
      this.origin
    );
  }
}
