import UrlUtils from './url';
import SETTINGS from '@/modules/core/constants/settings';

export function getVersionSuffix() {
  return `v${SETTINGS.API_VERSION}/`;
}

class ApiFolksEndpointBuilder {
  resourceUrl: string;
  filterableParameters: any;
  sortableParameters: any;
  paginatedParameters: any;
  queryParameters: any;
  embeddedResources: string[];

  constructor(url: string) {
    this.resourceUrl = url;
    this.filterableParameters = {};
    this.sortableParameters = {};
    this.paginatedParameters = {};
    this.queryParameters = {};
    this.embeddedResources = [];
  }

  withFilterableParameters(params: any) {
    this.filterableParameters = this.mergeParams(
      this.filterableParameters,
      params
    );
    return this;
  }

  withSortableParameters(params: any) {
    this.sortableParameters = this.mergeParams(this.sortableParameters, params);
    return this;
  }

  withPaginatedParameters(params: any) {
    this.paginatedParameters = this.mergeParams(
      this.paginatedParameters,
      params
    );
    return this;
  }

  withQueryParameters(params: any) {
    this.queryParameters = this.mergeParams(this.queryParameters, params);
    return this;
  }

  withEmbeddedResources(resources: string[]) {
    this.embeddedResources = resources;
    return this;
  }

  withInactiveEmployees(value: boolean) {
    return this.withQueryParameters({ with_inactive: value ? 1 : 0 });
  }

  /**
   * @return {String}
   */
  build() {
    let outputUrl = `${this.resourceUrl}`;
    if (this.filterableParameters) {
      outputUrl = UrlUtils.appendQueryParamsString(
        outputUrl,
        UrlUtils.buildQueryParametersString(this.filterableParameters)
      );
    }

    if (this.sortableParameters) {
      outputUrl = UrlUtils.appendQueryParamsString(
        outputUrl,
        UrlUtils.buildQueryParametersString(this.sortableParameters)
      );
    }

    if (this.paginatedParameters) {
      outputUrl = UrlUtils.appendQueryParamsString(
        outputUrl,
        UrlUtils.buildQueryParametersString(this.paginatedParameters)
      );
    }

    if (this.queryParameters) {
      outputUrl = UrlUtils.appendQueryParamsString(
        outputUrl,
        UrlUtils.buildQueryParametersString(this.queryParameters)
      );
    }

    if (this.embeddedResources.length) {
      outputUrl = UrlUtils.appendQueryParamsString(
        outputUrl,
        UrlUtils.buildEmbeddedResourcesString(this.embeddedResources)
      );
    }

    return `${getVersionSuffix()}${outputUrl}`;
  }

  private mergeParams(params: any, newParams: any) {
    return {
      ...params,
      ...newParams
    };
  }
}

export default ApiFolksEndpointBuilder;
