import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';
import { injectable, inject } from 'inversify';
import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type CreateAxiosDefaults
} from 'axios';
import { SETTINGS_TYPE } from '@sdk/settings/SettingTypes';
import type { FolksAxiosConfig } from '@sdk/modules/API/Interface/FolksAxiosConfig';
import type { SettingsInterface } from '@sdk/settings/SettingsInterface';

@injectable()
export class APIProxy implements APIProxyInterface {
  private bearerToken?: string;
  private refreshToken?: string;
  private axios: AxiosInstance;

  constructor(
    @inject(SETTINGS_TYPE.SettingInterface) private settings: SettingsInterface
  ) {
    this.axios = axios.create(this.getAxiosConfig());
  }

  async post<T = unknown, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D> & FolksAxiosConfig
  ): Promise<AxiosResponse<T>> {
    return this.axios.post<T, AxiosResponse<T>, D>(url, data, config);
  }

  setBearerToken(token: string) {
    this.bearerToken = token;
  }

  setRefreshToken(token: string) {
    this.refreshToken = token;
  }

  getAuthentication(): string {
    return `Bearer ${this.bearerToken}`;
  }

  getRefreshToken(): string | undefined {
    return this.refreshToken;
  }

  private getAxiosConfig(): CreateAxiosDefaults {
    return {
      baseURL: this.settings.api.baseUrl,
      withCredentials: true,
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      timeout: 60000
    };
  }
}
