import { MessageEventData } from '@/modules/core/objects/MessageEventData';
import { LEGACY_TYPE } from '@/constants/legacyType';
import useDebugLogging from '@/modules/core/composables/useDebugLogging';
import type { ProgramData } from '@/mapping/index.types';
import { getLegacyIframeId } from '@/mapping';
import { getNewUUID } from '@/modules/core/utils/uuid';

export default function useMessageSender() {
  const getCurrentFrame = (frameId: string) => {
    const frames = window.frames as unknown as {
      [key: string]: HTMLIFrameElement;
    };

    if (!(frameId in frames)) {
      throw new Error(`frame with id: "${frameId}" is not loaded`);
    }

    const iframe = frames[frameId];
    if (!iframe) {
      throw new Error('Could not get content window of current frame');
    }

    return iframe;
  };

  const getContentWindow = (frameId: string) => {
    const iframe = getCurrentFrame(frameId);
    if (!iframe.contentWindow) {
      throw new Error('Could not get content window of current frame');
    }

    return iframe.contentWindow;
  };

  const postToCurrentFrame = (
    frameId: string,
    message: MessageEventData,
    origin: string
  ) => {
    try {
      const currentFrame = getContentWindow(frameId);
      currentFrame.postMessage(message.toString(), origin);

      const { debugModeLog } = useDebugLogging();
      debugModeLog(`Sending data back to ${frameId}.`, message, origin);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message); // Handle the error appropriately
      }
    }
  };

  const getOriginForFrame = (programData: ProgramData) => {
    const { getSetting } = useSettings();
    const postMessageAuthorizedOrigins = getSetting(
      'postMessageAuthorizedOrigins'
    );
    switch (programData.isLegacy) {
      case LEGACY_TYPE.LEGACY_V2:
        return postMessageAuthorizedOrigins.folks_FE;
      case LEGACY_TYPE.LEGACY_MONOLITH:
        return postMessageAuthorizedOrigins.folks_LEGACY;
      default:
        return '*';
    }
  };

  const sendMessageToProgramFrame = (
    messageData: MessageEventData,
    programData: ProgramData
  ) => {
    const programOrigin = getOriginForFrame(programData);
    postToCurrentFrame(
      getLegacyIframeId(programData),
      messageData,
      programOrigin
    );
  };

  const buildMessageEventDataToSend = (
    eventType: string,
    data: any,
    fromPath: string,
    origin: string
  ): MessageEventData => {
    return new MessageEventData(
      eventType,
      getNewUUID(),
      data,
      fromPath,
      origin
    );
  };

  return {
    getCurrentFrame,
    sendMessageToProgramFrame,
    buildMessageEventDataToSend
  };
}
