interface LocalStorageWithExpiration {
  getItem(key: string): null | any;

  setItem(key: string, value: any, millisecondsFromNow?: number): void;

  removeItem(key: string): void;

  clear(): void;
}

interface LocalStorageWithExpirationValue {
  data: any;
  expireTime: number | null;
}

const localStorageWithExpiration: LocalStorageWithExpiration = {
  getItem(key) {
    const storageValue = localStorage.getItem(key);
    if (storageValue) {
      const result: LocalStorageWithExpirationValue = JSON.parse(storageValue);
      if (result) {
        // if the entry is expired remove the entry and return null
        if (result.expireTime && result.expireTime <= Date.now()) {
          localStorage.removeItem(key);
          return null;
        }
        return result.data;
      }
    }

    return null;
  },
  // default expiry is 30 days in milliseconds
  setItem(key, value, millisecondsFromNow = 30 * 60 * 60 * 1000) {
    const result: LocalStorageWithExpirationValue = {
      data: value,
      expireTime: null
    };
    if (millisecondsFromNow) {
      result.expireTime = Date.now() + millisecondsFromNow;
    }
    localStorage.setItem(key, JSON.stringify(result));
  },

  // remove the entry with the given key
  removeItem(key) {
    localStorage.removeItem(key);
  },

  // clear the storage
  clear() {
    localStorage.clear();
  }
};

export default localStorageWithExpiration;
