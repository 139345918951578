<template>
  <div class="d-flex flex-column fill-height">
    <AppHeader
      :back-route="backRoute"
      :tabs="tabs"
      :title="t('title')"
    />
    <VContainer
      class="flex-grow-1 pa-0"
      fluid
    >
      <VRow
        class="fill-height"
        no-gutters
      >
        <router-view />
      </VRow>
    </VContainer>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import type { Tab } from '@/components/AppHeader.types';
import {
  BASE_ROUTE_TIMESHEETS,
  BASE_ROUTE_TIMESHEETS_CONFIG
} from '@/modules/timesheets/constants/urls';

definePage({
  meta: {
    needAuth: true
  },
  redirect: {
    name: '/time/timesheets/configurations/projects'
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Configuration de la feuille de temps',
      projects: 'Liste des projets',
      holidays: 'Paramètres des jours fériés',
      legend: 'Gestion de la légende',
      calculations: 'Configuration des calculs'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheet configuration',
      projects: 'Projects List',
      holidays: 'Holidays Settings',
      legend: 'Legend Management',
      calculations: 'Calculations Configuration'
    }
  }
});

const backRoute = BASE_ROUTE_TIMESHEETS;

const tabs = [
  {
    to: `${BASE_ROUTE_TIMESHEETS_CONFIG}projects`,
    title: t('projects')
  },
  {
    to: `${BASE_ROUTE_TIMESHEETS_CONFIG}holidays`,
    title: t('holidays')
  },
  {
    to: `${BASE_ROUTE_TIMESHEETS_CONFIG}legend`,
    title: t('legend')
  },
  {
    to: `${BASE_ROUTE_TIMESHEETS_CONFIG}calculations`,
    title: t('calculations')
  }
] as Tab[];
</script>
<style lang="sass" scoped></style>
