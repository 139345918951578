/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import pinia from './pinia';
import router from './vueRouter';
import i18n from './vueI18n';
import { bugsnagStartAndGetVue, bugsnagPerformanceStart } from './bugsnag';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(bugsnagStartAndGetVue());
  bugsnagPerformanceStart(router);
}
