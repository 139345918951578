<template>
  <VContainer>
    <VRow
      align="center"
      justify="center"
    >
      <VCol>
        <h1 class="text-h3">{{ t('title') }}</h1>
        <p>
          <VBtn to="/">{{ t('homeBtnLabel') }}</VBtn>
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Mmmmmm, vous semblez perdu (404)',
      homeBtnLabel: "Retourner à l'accueil"
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Mmmmmm, you seem lost (404)',
      homeBtnLabel: 'Go back home'
    }
  }
});
</script>
