import axios from 'axios';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';

export const refreshToken = async () => {
  const { refreshToken } = useAuthenticationStore();
  return refreshToken();
};

export function retryCurrentRequest(errorResponse: any) {
  const config = appendTokenToRequest(errorResponse.config);
  return axios.request(config);
}

export function appendTokenToRequest(config: any) {
  const {
    authData: { access_token }
  } = useAuthenticationStore();
  if (access_token) {
    config.headers['Authorization'] = `Bearer ${access_token}`;
  }
  return config;
}
