import SETTINGS from '@/modules/core/constants/settings';

export default function useSettings() {
  const getSetting = <T extends keyof typeof SETTINGS>(
    key: T
  ): (typeof SETTINGS)[T] => {
    if (key in SETTINGS) {
      return SETTINGS[key];
    }
    throw new Error(`Setting with key "${key}" not found`);
  };

  return {
    getSetting
  };
}
