import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { ContainerModule } from 'inversify';
import { API_BINDING_TYPES } from '@sdk/modules/API/FolksSDKAPIModuleBindTypes';
import { APIProxy } from '@sdk/modules/API/proxies/APIProxy';
import type { APIProxyInterface } from '@sdk/modules/API/Interface/APIProxyInterface';

export class FolksSDKApiModule implements FolksSDKModuleInterface {
  getContainerModule(): ContainerModule {
    return new ContainerModule((bind) => {
      bind<APIProxyInterface>(API_BINDING_TYPES.APIProxyInterface)
        .to(APIProxy)
        .inSingletonScope();
    });
  }
}
