/**
 * Automatic routes for `./src/pages/*.vue`
 */
import { createRouter, createWebHistory } from 'vue-router/auto';
import { setupLayouts } from 'virtual:generated-layouts';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { useUserStore } from '@/modules/core/stores/user';
import { fillPendo } from '@/plugins/pendo';
import { fillBugsnag } from '@/plugins/bugsnag';
import { useFeatureFlagsStore } from '@/modules/core/stores/featureFlags';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts
});

const loginRoute = '/login/';

const canUserAccess = async () => {
  const authStore = useAuthenticationStore();
  if (!authStore.authData.access_token) {
    authStore.getFromLocalStorage();
  }
  return !!authStore.authData.access_token;
};

router.beforeEach(async (to) => {
  const canAccess = await canUserAccess();
  const featureFlagsStore = useFeatureFlagsStore();
  if (featureFlagsStore.featureFlags.length === 0) {
    await featureFlagsStore.getFeatureFlags();
  }

  if (to.meta.needAuth) {
    if (!canAccess) {
      return loginRoute;
    }
  }

  if (to.meta.isAuthPages) {
    if (canAccess) {
      return '/';
    }
  }

  if (canAccess) {
    const userStore = useUserStore();
    if (!userStore.id) {
      await userStore.getCurrentUser();
      fillBugsnag();
      fillPendo();
    }
  }
  return true;
});

export default router;
