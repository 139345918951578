import type { Program } from '@/mapping/index.types';
import { LEGACY_TYPE } from '@/constants/legacyType';
import { BASE_ROUTE_VACATIONS } from '@/modules/vacations/constants/urls';

const VACATIONS_MAPPING = [
  {
    name: 'analysis',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206025,
    module: 'vacations',
    routeName: `${BASE_ROUTE_VACATIONS}analysis`,
    iframeUrl: '/legacy/vacation/analysis'
  },
  {
    name: 'add',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: 'vacations',
    routeName: `${BASE_ROUTE_VACATIONS}add`,
    iframeUrl: '/legacy/vacation/control'
  },
  {
    name: 'create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 401000,
    module: 'vacations',
    routeName: `${BASE_ROUTE_VACATIONS}create`,
    iframeUrl: '/legacy/vacation/create'
  },
  {
    name: 'edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 206030,
    module: 'vacations',
    routeName: `${BASE_ROUTE_VACATIONS}:id`,
    iframeUrl: '/legacy/vacation/:id/edit'
  }
] as Program[];

export default VACATIONS_MAPPING;
