import { defineStore } from 'pinia';

export const useEmployeeStore = defineStore('employee', {
  state: (): {
    employee: {
      id: null | number;
    };
  } => ({
    employee: {
      id: null
    }
  }),
  actions: {
    formatEmployee(employeeData: any) {
      if (employeeData) {
        this.employee.id = employeeData.id;
      }
    }
  }
});
