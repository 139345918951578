import BASE_MAPPING from '@/mapping/program';
import VACATIONS_MAPPING from '@/modules/vacations/mapping/program';
import type { Program, ProgramData } from '@/mapping/index.types';
import TIMESHEETS_PROGRAMS from '@/modules/timesheets/mapping/program';
import ABSENCES_MAPPING from '@/modules/absences/mapping/program';
import OVERTIMES_MAPPING from '@/modules/overtimes/mapping/program';

export function getLegacyRoutesMap(): ProgramData[] {
  return [
    ...BASE_MAPPING,
    ...ABSENCES_MAPPING,
    ...VACATIONS_MAPPING,
    ...OVERTIMES_MAPPING,
    ...TIMESHEETS_PROGRAMS
  ].map(addProgramDataValues);
}

export const replaceDynamicParamsInString = (
  str: string,
  params: Record<any, string>
) => {
  return Object.keys(params).reduce((acc, key) => {
    return acc.replace(`:${key}`, params[key]);
  }, str);
};

const getRegexpForDynmaicRoute = (route: string) => {
  return new RegExp(`^${route.replace(/:\w+/g, '([^/]+)')}/?$`);
};

function replaceLastCharacterIfSlash(str: string, replacement = ''): string {
  // Check if the last character is '/'
  if (str.endsWith('/') && str.length > 1) {
    // Replace the last character and return the new string
    return str.slice(0, -1) + replacement;
  }
  // Return the original string if no replacement is needed
  return str;
}

export function getProgramDataByID(programID: number) {
  if (!programID) {
    throw new Error('Program ID is required');
  }

  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return program.programId === programID;
  });

  if (!foundProgramData) {
    throw new Error(`ProgramID ${programID} is not valid`);
  }

  return foundProgramData;
}

export function getProgramDataByModuleAndName(module: string, name: string) {
  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return program.module === module && program.name === name;
  });

  if (!foundProgramData) {
    throw new Error(`Name ${name} and module ${module} is not valid`);
  }

  return foundProgramData;
}

export function getProgramDataByRoute(routeName: string) {
  if (!routeName) {
    throw new Error('Route name is required');
  }

  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return replaceLastCharacterIfSlash(routeName).match(program.routeRegexp);
  });

  if (!foundProgramData) {
    throw new Error(`Route name ${routeName} is not valid`);
  }

  return foundProgramData;
}

export function getProgramDataByIframeRoute(routeName: string) {
  if (!routeName) {
    throw new Error('Route name is required');
  }

  const foundProgramData = getLegacyRoutesMap().find((program) => {
    return replaceLastCharacterIfSlash(routeName).match(program.iframeRegexp);
  });

  if (!foundProgramData) {
    throw new Error(`Route name ${routeName} is not valid`);
  }

  return foundProgramData;
}

function addProgramDataValues(programData: Program): ProgramData {
  programData.routeName = replaceLastCharacterIfSlash(
    programData.routeName,
    '/'
  );

  return {
    ...programData,
    routeRegexp: getRegexpForDynmaicRoute(programData.routeName),
    iframeRegexp: getRegexpForDynmaicRoute(programData.iframeUrl)
  };
}

export function getLegacyIframeId(programData: ProgramData) {
  return `legacy-frame-${programData.module}-${programData.name}`;
}
