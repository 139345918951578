<template>
  <v-snackbar
    v-model="snackbar"
    location="top"
  >
    {{ snackbarMessage }}
  </v-snackbar>
  <RouterView />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useThemeStore } from '@/stores/theme';
import { useTheme } from 'vuetify';
import useMessageListener from '@/modules/core/composables/PostMessage/useMessageListener';
import type { MessageEventData } from '@/modules/core/objects/MessageEventData';

const { theme } = storeToRefs(useThemeStore());
const themeVuetify = useTheme();
themeVuetify.global.name.value = theme.value;

const { addHandler } = useMessageListener({ name: 'app' });

const snackbar = ref(false);
const snackbarMessage = ref('');
addHandler('toast', (eventData: MessageEventData) => {
  console.log('toastEvent', eventData);
  snackbarMessage.value = eventData.data.message;
  snackbar.value = true;
});
</script>
<style>
/*html {
  overflow-y: hidden !important;
}*/
</style>
