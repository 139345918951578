import _definePage_default_0 from '/builds/folkshr/folks-spa/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/builds/folkshr/folks-spa/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from '/builds/folkshr/folks-spa/src/pages/company/standards/employee.vue?definePage&vue'
import _definePage_default_4 from '/builds/folkshr/folks-spa/src/pages/company/standards/organizational.vue?definePage&vue'
import _definePage_default_5 from '/builds/folkshr/folks-spa/src/pages/company/standards.vue?definePage&vue'
import _definePage_default_6 from '/builds/folkshr/folks-spa/src/modules/authentication/pages/login.vue?definePage&vue'
import _definePage_default_7 from '/builds/folkshr/folks-spa/src/modules/absences/pages/index.vue?definePage&vue'
import _definePage_default_8 from '/builds/folkshr/folks-spa/src/modules/overtimes/pages/index.vue?definePage&vue'
import _definePage_default_9 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/index.vue?definePage&vue'
import _definePage_default_10 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/calculations.vue?definePage&vue'
import _definePage_default_11 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/holidays.vue?definePage&vue'
import _definePage_default_12 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/legend.vue?definePage&vue'
import _definePage_default_13 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/projects.vue?definePage&vue'
import _definePage_default_14 from '/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations.vue?definePage&vue'
import _definePage_default_15 from '/builds/folkshr/folks-spa/src/modules/vacations/pages/index.vue?definePage&vue'
import _definePage_default_16 from '/builds/folkshr/folks-spa/src/pages/user-params.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/builds/folkshr/folks-spa/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/builds/folkshr/folks-spa/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/company',
    /* internal name: '/company' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'standards',
        name: '/company/standards',
        component: () => import('/builds/folkshr/folks-spa/src/pages/company/standards.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'employee',
            name: '/company/standards/employee',
            component: () => import('/builds/folkshr/folks-spa/src/pages/company/standards/employee.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: 'organizational',
            name: '/company/standards/organizational',
            component: () => import('/builds/folkshr/folks-spa/src/pages/company/standards/organizational.vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
  _definePage_default_5
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: '/login',
    component: () => import('/builds/folkshr/folks-spa/src/modules/authentication/pages/login.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/time',
    /* internal name: '/time' */
    /* no component */
    children: [
      {
        path: 'absences',
        /* internal name: '/time/absences' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time/absences/',
            component: () => import('/builds/folkshr/folks-spa/src/modules/absences/pages/index.vue'),
            children: [
              {
                path: 'analysis',
                name: '/time/absences//analysis',
                component: () => import('/builds/folkshr/folks-spa/src/modules/absences/pages/index/analysis.vue'),
                /* no children */
              }
            ],
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'overtimes',
        /* internal name: '/time/overtimes' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time/overtimes/',
            component: () => import('/builds/folkshr/folks-spa/src/modules/overtimes/pages/index.vue'),
            children: [
              {
                path: 'analysis',
                name: '/time/overtimes//analysis',
                component: () => import('/builds/folkshr/folks-spa/src/modules/overtimes/pages/index/analysis.vue'),
                /* no children */
              }
            ],
          },
  _definePage_default_8
  )
        ],
      },
      {
        path: 'timesheets',
        /* internal name: '/time/timesheets' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time/timesheets/',
            component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/index.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'configurations',
            name: '/time/timesheets/configurations',
            component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'calculations',
                name: '/time/timesheets/configurations/calculations',
                component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/calculations.vue'),
                /* no children */
              },
  _definePage_default_10
  ),
  _mergeRouteRecord(
              {
                path: 'holidays',
                name: '/time/timesheets/configurations/holidays',
                component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/holidays.vue'),
                /* no children */
              },
  _definePage_default_11
  ),
  _mergeRouteRecord(
              {
                path: 'legend',
                name: '/time/timesheets/configurations/legend',
                component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/legend.vue'),
                /* no children */
              },
  _definePage_default_12
  ),
  _mergeRouteRecord(
              {
                path: 'projects',
                name: '/time/timesheets/configurations/projects',
                component: () => import('/builds/folkshr/folks-spa/src/modules/timesheets/pages/configurations/projects.vue'),
                /* no children */
              },
  _definePage_default_13
  )
            ],
          },
  _definePage_default_14
  )
        ],
      },
      {
        path: 'vacations',
        /* internal name: '/time/vacations' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/time/vacations/',
            component: () => import('/builds/folkshr/folks-spa/src/modules/vacations/pages/index.vue'),
            children: [
              {
                path: 'analysis',
                name: '/time/vacations//analysis',
                component: () => import('/builds/folkshr/folks-spa/src/modules/vacations/pages/index/analysis.vue'),
                /* no children */
              }
            ],
          },
  _definePage_default_15
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/user-params',
    name: '/user-params',
    component: () => import('/builds/folkshr/folks-spa/src/pages/user-params.vue'),
    /* no children */
  },
  _definePage_default_16
  )
]
