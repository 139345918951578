<template>
  <div class="d-flex flex-column fill-height">
    <AppHeader
      :title="t('title')"
      @add:click="showDialog"
    >
      <template #add-modal>
        <FAbsencesDialog
          v-if="isDialogShowed"
          :is-showed="isDialogShowed"
          :route="addRoute"
          :title="t('addModalTitle')"
          @dialog:update="updateDialog"
        />
      </template>
    </AppHeader>
    <VContainer
      class="flex-grow-1 pa-0"
      fluid
    >
      <VRow
        class="fill-height"
        no-gutters
      >
        <router-view />
      </VRow>
    </VContainer>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import { BASE_ROUTE_ABSENCES } from '@/modules/absences/constants/urls';

definePage({
  meta: {
    needAuth: true
  },
  redirect: `/time/absences/analysis/`
});

const addRoute = `${BASE_ROUTE_ABSENCES}add/`;

const { showDialog, isDialogShowed, updateDialog } = useDialogLegacyIframe();

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Absences',
      addModalTitle: "Ajout d'absences"
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Absences',
      addModalTitle: 'Add absences'
    }
  }
});
</script>
<style lang="sass" scoped></style>
