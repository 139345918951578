import type { Program } from '@/mapping/index.types';
import { LEGACY_TYPE } from '@/constants/legacyType';

const BASE_MAPPING = [
  {
    name: 'home',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 100000,
    module: 'base',
    routeName: '',
    iframeUrl: '/',
    actions: 'none'
  },
  {
    name: 'user-params',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 800000,
    module: 'base',
    routeName: '/user-params',
    iframeUrl: '/user-params',
    subject: 'user-params',
    actions: 'write'
  },
  {
    name: 'organizational-standards',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505010,
    module: 'base',
    routeName: '/company/standards/organizational',
    iframeUrl: '',
    subject: '',
    actions: ''
  },
  {
    name: 'employee-standards',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505020,
    module: 'base',
    routeName: '/company/standards/employee',
    iframeUrl: '',
    subject: '',
    actions: ''
  }
] as Program[];

export default BASE_MAPPING;
