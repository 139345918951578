import ApiFolksEndpointBuilder from '@/modules/core/utils/apiFolks/ApiFolksEndpointBuilder';
import proxy from '@/modules/core/utils/apiFolks/proxy';

const baseEndpoint = `users/current`;

export async function getCurrentUser() {
  return proxy.get(
    new ApiFolksEndpointBuilder(baseEndpoint)
      .withEmbeddedResources([
        'employee',
        'employee.department',
        'employee.position',
        'employee.province',
        'employee.workingSite',
        'employee.union',
        'employee.norms',
        'company',
        'company.industry',
        'company.companyParameters'
      ])
      .build()
  );
}
