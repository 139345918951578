import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { ContainerModule } from 'inversify';
import type { AuthenticationServiceInterface } from '@sdk/modules/authentication/interfaces/AuthenticationServiceInterface';
import { AUTHENTICATION_BINDING_TYPES } from '@sdk/modules/authentication/FolksSDKAuthenticationModuleTypes';
import { AuthenticationService } from '@sdk/modules/authentication/services/AuthenticationService';

export class FolksSDKAuthenticationModule implements FolksSDKModuleInterface {
	getContainerModule(): ContainerModule {
		return new ContainerModule((bind) => {
			bind<AuthenticationServiceInterface>(AUTHENTICATION_BINDING_TYPES.AuthenticationServiceInterface).to(AuthenticationService);
		});
	}
}
