import { createI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import messages from '@intlify/unplugin-vue-i18n/messages';
import { setVeeValidateLocale } from '@/plugins/veeValidate';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-vue-i18n';

const localeStored = useLocalStorage<SUPPORTED_LANGUAGES>(
  'user-locale',
  SUPPORTED_LANGUAGES.fr
);
const i18n = createI18n({
  legacy: false,
  locale: localeStored.value,
  fallbackLocale: SUPPORTED_LANGUAGES.fr,
  messages,
  globalInjection: true
});

z.setErrorMap(makeZodI18nMap(i18n));

export const setI18nLanguage = (locale: SUPPORTED_LANGUAGES) => {
  localeStored.value = locale;
  i18n.global.locale.value = locale;
  setVeeValidateLocale(locale);
  const htmlElement = document.querySelector('html');
  if (htmlElement) {
    htmlElement.setAttribute('lang', locale);
  }
};
setI18nLanguage(localeStored.value);

export const getI18nLanguage = () => i18n.global.locale.value;

export default i18n;
