<template>
  <div class="d-flex flex-column fill-height">
    <AppHeader
      :configuration-route="configurationRoute"
      :title="t('title')"
    />
    <VContainer
      class="flex-grow-1 pa-0"
      fluid
    >
      <VRow
        class="fill-height"
        no-gutters
      >
        <LegacyIframe :iframe-route="$route.path" />
      </VRow>
    </VContainer>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import { BASE_ROUTE_TIMESHEETS_CONFIG } from '@/modules/timesheets/constants/urls';

definePage({
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Feuilles de temps'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Timesheets'
    }
  }
});

const configurationRoute = BASE_ROUTE_TIMESHEETS_CONFIG;
</script>
<style lang="sass" scoped></style>
