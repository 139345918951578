import type { Program } from '@/mapping/index.types';
import { LEGACY_TYPE } from '@/constants/legacyType';
import { BASE_ROUTE_OVERTIMES } from '@/modules/overtimes/constants/urls';

const OVERTIMES_MAPPING = [
  {
    name: 'analysis',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207030,
    module: 'overtimes',
    routeName: `${BASE_ROUTE_OVERTIMES}analysis`,
    iframeUrl: '/legacy/overtime/analysis'
  },
  {
    name: 'add',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: 'overtimes',
    routeName: `${BASE_ROUTE_OVERTIMES}add`,
    iframeUrl: '/legacy/overtime/control'
  },
  {
    name: 'create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 403000,
    module: 'overtimes',
    routeName: `${BASE_ROUTE_OVERTIMES}create`,
    iframeUrl: '/legacy/overtime/create'
  },
  {
    name: 'edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 207040,
    module: 'overtimes',
    routeName: `${BASE_ROUTE_OVERTIMES}:id`,
    iframeUrl: '/legacy/overtime/:id/edit'
  }
] as Program[];

export default OVERTIMES_MAPPING;
