import type { FolksSDKModuleInterface } from '@sdk/interfaces/FolksSDKModuleInterface';
import { Container } from 'inversify';
import { FolksSDKAuthenticationModule } from '@sdk/modules/authentication/FolksSDKAuthenticationModule';
import { FolksSDKApiModule } from '@sdk/modules/API/FolksSDKApiModule';
import SettingsDefault from '@sdk/settings/SettingsDefault';
import { SETTINGS_TYPE } from '@sdk/settings/SettingTypes';
import type { SettingsInterface } from '@sdk/settings/SettingsInterface';

type PartialExcept<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

export class FolksSDK {
  private static instance: FolksSDK | null = null;
  private readonly container: Container;
  private isInitialized: boolean = false;

  constructor() {
    this.container = new Container();
  }

  public static getContainer(): Container {
    return this.getInstance().container;
  }

  public static getInstance(): FolksSDK {
    if (!FolksSDK.instance) {
      FolksSDK.instance = new FolksSDK();
    }

    return FolksSDK.instance;
  }

  public static bindInstance(abstract: string | symbol, concrete: unknown) {
    if (this.getContainer().isBound(abstract)) {
      this.getContainer().rebind(abstract).toConstantValue(concrete);
    } else {
      this.getContainer().bind(abstract).toConstantValue(concrete);
    }
  }

  public static snapshot() {
    this.getContainer().snapshot();
  }

  public static restore() {
    this.getContainer().restore();
  }

  public static initialize(
    userSettings: PartialExcept<SettingsInterface, 'api'>
  ) {
    if (!this.getInstance().isInitialized) {
      this.registerSettings(userSettings);

      const registeredModules: FolksSDKModuleInterface[] = [
        new FolksSDKAuthenticationModule(),
        new FolksSDKApiModule()
      ];

      const container = this.getContainer();

      const containerModules = registeredModules.map(
        (module: FolksSDKModuleInterface) => {
          return module.getContainerModule();
        }
      );

      container.load(...containerModules);

      this.getInstance().isInitialized = true;
    }
  }

  public static registerSettings(
    userSettings: PartialExcept<SettingsInterface, 'api'>
  ) {
    const settings = {
      ...userSettings,
      ...SettingsDefault
    };

    this.getContainer()
      .bind<SettingsInterface>(SETTINGS_TYPE.SettingInterface)
      .toConstantValue(settings);
  }
}
