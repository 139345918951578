<template>
  <div class="d-flex flex-column fill-height">
    <AppHeader
      :tabs="tabs"
      :title="t('title')"
    />
    <VContainer
      class="flex-grow-1 pa-0"
      fluid
    >
      <VRow
        class="fill-height"
        no-gutters
      >
        <router-view />
      </VRow>
    </VContainer>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import type { Tab } from '@/components/AppHeader.types';

definePage({
  redirect: '/company/standards/organizational',
  meta: {
    needAuth: true
  }
});

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      title: 'Normes',
      organizational: 'Normes organisationnels',
      employee: 'Normes Employé'
    },
    [SUPPORTED_LANGUAGES.en]: {
      title: 'Standards'
    }
  }
});

const tabs = [
  {
    to: '/company/standards/organizational',
    title: t('organizational')
  },
  {
    to: '/company/standards/employee',
    title: t('employee')
  }
] as Tab[];
</script>
