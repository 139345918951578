import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'material-symbols/index.css';
import 'vuetify/styles';
import { md3 } from 'vuetify/blueprints';
import { md } from 'vuetify/iconsets/md';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { createVuetify, type ThemeDefinition } from 'vuetify';

const folksTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#5D5E5F',
    'on-primary': '#FFFFFF',
    secondary: '#5F5E5E',
    'on-secondary': '#FFFFFF',
    tertiary: '#FFCB05',
    'on-tertiary': '#3D3F40',
    error: '#BA1A1A',
    'on-error': '#FFFFFF'
  }
};

export default createVuetify({
  blueprint: md3,
  theme: {
    defaultTheme: 'folksTheme',
    themes: {
      folksTheme
    }
  },
  icons: {
    aliases,
    defaultSet: 'mdi',
    sets: {
      mdi,
      md
    }
  },
  defaults: {
    VBtn: {
      color: 'tertiary',
      class: 'text-none text-label-large',
      variant: 'flat'
    }
  }
});
