<template>
  <VApp>
    <AppTopNav />
    <AppNavigationRail />
    <VMain>
      <router-view />
    </VMain>
  </VApp>
</template>

<script lang="ts" setup></script>
