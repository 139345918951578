import { FolksSDK } from '@sdk/FolksSDK';

export default function useSDKContainer() {
  const resolve = <T>(type: string | symbol): T => {
    return FolksSDK.getContainer().get<T>(type);
  };

  return {
    resolve
  };
}
