const testIdDirective = {
  beforeMount: function (el: Element, { value }: { value: string }) {
    if (import.meta.env.VITE_APP_DISPLAY_TEST_IDS === '1') {
      const valueAsArray = Array.isArray(value) ? value : [value];

      valueAsArray.forEach((val) => {
        const classValidValue = val.replace('.', '_');
        el.classList.add(`--test-id-${classValidValue}`);
      });
    }
  }
};

export default testIdDirective;
