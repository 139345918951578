<template>
  <VAppBar
    app
    elevation="1"
    height="56"
  >
    <template #prepend>
      <VFab
        fab
        icon="true"
        rounded
        to="/"
        variant="plain"
      >
        <FolksLogoSVG />
      </VFab>
    </template>
    <template #append>
      <VMenu>
        <template #activator="{ props }">
          <VBtn
            v-dynamic-data-attr="{ all: `button-menu-my-account` }"
            icon="true"
            v-bind="props"
          >
            <VIcon
              v-dynamic-data-attr="{ all: `icon-top-menu-my-account` }"
              class="material-symbols-outlined"
              icon="md:account_circle"
            />
          </VBtn>
        </template>
        <VList>
          <VListItem
            v-dynamic-data-attr="{ all: `top-navigation-user-infos` }"
            :subtitle="userStore.role"
            :title="`${userStore.first_name} ${userStore.last_name}`"
          >
            <template #prepend>
              <VAvatar
                v-dynamic-data-attr="{ all: `img-user-avatar` }"
                color="surface-variant"
                size="small"
              >
                {{ userStore.initials }}
              </VAvatar>
            </template>
          </VListItem>
          <VListItem
            v-dynamic-data-attr="{ all: `top-menu-user-settings` }"
            to="/user-params"
          >
            {{ t('userMenu.userSettings') }}
          </VListItem>
          <VDivider />
          <VListItem
            v-dynamic-data-attr="{ all: `top-menu-logout` }"
            prepend-icon="mdi:mdi-logout"
            @click="logout()"
          >
            {{ t('userMenu.logout') }}
          </VListItem>
        </VList>
      </VMenu>
    </template>
  </VAppBar>
</template>

<script lang="ts" setup>
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/modules/core/stores/user';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      searchPlaceholder: 'Rechercher un employé',
      userMenu: {
        userSettings: "Paramètres de l'utilisateur",
        logout: 'Déconnexion'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      searchPlaceholder: 'Search for an employee',
      userMenu: {
        userSettings: 'User settings',
        logout: 'Logout'
      }
    }
  }
});

const { logout } = useAuthenticationStore();

const userStore = useUserStore();
</script>
<style lang="sass" scoped></style>
