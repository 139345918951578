import type { ObjectDirective, DirectiveBinding } from 'vue'
import type { DynamicDataAttributeDirectiveTypes } from '@/modules/core/interfaces/DynamicDataAttributeDirective.types';

type DynamicAttributeBinding = DirectiveBinding<DynamicDataAttributeDirectiveTypes> & {
  arg?: string;
}

const DynamicDataAttributeDirective: ObjectDirective<HTMLElement, DynamicDataAttributeDirectiveTypes> = {
  mounted(el, binding) {
    updateAttributes(el, binding);
  },
  updated(el, binding) {
    updateAttributes(el, binding);
  }
}

function updateAttributes(el: HTMLElement, binding: DynamicAttributeBinding) {
  const { value } = binding;

  if (!value.all) {
    console.error('The dynamic attribute value "all" is mandatory.');
    return;
  }

  const attributes = ['all', 'cy', 'test'];

  attributes.forEach(attr => {
    const attrValue: string = attr === 'all' ? value.all : (value[attr] || value.all);
    el.setAttribute(`data-${attr}`, attrValue);
  });
}

export default DynamicDataAttributeDirective
