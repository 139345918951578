import type { AuthenticationResponseInterface } from '@sdk/modules/authentication/interfaces/AuthenticationResponseInterface';

export abstract class AbstractAuthenticationResponse<T>
  implements AuthenticationResponseInterface<T>
{
  constructor(private readonly payload: T) {}

  getPayload(): T {
    return this.payload;
  }
}
