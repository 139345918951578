import type { Program } from '@/mapping/index.types';
import { LEGACY_TYPE } from '@/constants/legacyType';
import { BASE_ROUTE_ABSENCES } from '@/modules/absences/constants/urls';

const ABSENCES_MAPPING = [
  {
    name: 'analysis',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201520,
    module: 'absences',
    routeName: `${BASE_ROUTE_ABSENCES}analysis`,
    iframeUrl: '/legacy/absence/analysis'
  },
  {
    name: 'add',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: 'absences',
    routeName: `${BASE_ROUTE_ABSENCES}add`,
    iframeUrl: '/legacy/absence/control'
  },
  {
    name: 'create',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 402000,
    module: 'absences',
    routeName: `${BASE_ROUTE_ABSENCES}create`,
    iframeUrl: '/legacy/absence/create'
  },
  {
    name: 'edit',
    isLegacy: LEGACY_TYPE.LEGACY_V2,
    programId: 201530,
    module: 'absences',
    routeName: `${BASE_ROUTE_ABSENCES}:id`,
    iframeUrl: '/legacy/absence/:id/edit'
  }
] as Program[];

export default ABSENCES_MAPPING;
