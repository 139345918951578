<template>
  <VRow
    class="px-4 px-md-4 w-100"
    no-gutters
  >
    <VCol
      align-self="center"
      cols="12"
    >
      <VCard
        class="mx-auto"
        elevation="0"
        max-width="800"
      >
        <VCardTitle class="text-headline-medium">{{ t('login') }}</VCardTitle>
        <VCardSubtitle style="white-space: wrap">{{
          t('subtitle')
        }}</VCardSubtitle>
        <VCardText>
          <VForm
            novalidate
            @submit.prevent="onSubmit"
          >
            <VContainer class="pa-0">
              <VRow>
                <VCol
                  v-if="errorMessage"
                  cols="12"
                >
                  <VAlert
                    v-test-id="`login-error-message`"
                    :text="errorMessage"
                    type="error"
                    variant="tonal"
                  />
                </VCol>
                <VCol cols="12">
                  <VTextField
                    v-model="email.value.value"
                    v-dynamic-data-attr="{ all: 'input-login-email' }"
                    v-test-id="`login-email`"
                    :error-messages="email.errorMessage.value"
                    :label="t('username')"
                    :placeholder="t('usernamePlaceholder')"
                    persistent-placeholder
                    type="email"
                    variant="outlined"
                    w-full
                  />
                </VCol>
                <VCol cols="12">
                  <VTextField
                    v-model="password.value.value"
                    v-dynamic-data-attr="{ all: 'input-login-password' }"
                    v-test-id="`login-password`"
                    :append-inner-icon="
                      showPassword ? 'mdi:mdi-eye' : 'mdi:mdi-eye-off'
                    "
                    :error-messages="password.errorMessage.value"
                    :label="t('password')"
                    :type="showPassword ? 'text' : 'password'"
                    persistent-placeholder
                    placeholder="***********"
                    variant="outlined"
                    @click:append-inner="showPassword = !showPassword"
                  />
                </VCol>
                <VCol
                  class="pt-0"
                  cols="12"
                >
                  <ForgotMyPassword />
                </VCol>
              </VRow>
            </VContainer>
            <VCardActions
              class="pt-4"
              style="flex-direction: column"
            >
              <VBtn
                v-test-id="`login-submit-btn`"
                v-dynamic-data-attr="{ all: 'button-login-submit' }"
                :loading="isSubmitting"
                :text="t('login')"
                type="submit"
                variant="flat"
              />
              <p class="pa-2 text-caption">
                <span>{{ t('needHelp.text') }}</span>
                <VBtn
                  v-dynamic-data-attr="{ all: 'button-login-help' }"
                  :href="helpCenterUrl"
                  :ripple="false"
                  :text="t('needHelp.link')"
                  color="secondary"
                  size="small"
                  target="_blank"
                  variant="plain"
                />
              </p>
            </VCardActions>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
    <OrganismTwoFactorModal
      :is-visible="twoFactorModalVisible"
      :loadings="twoFactorModalLoadings"
      :message="twoFactorModalMessage"
      @dismiss="handleTwoFactorModalDismiss"
    />
  </VRow>
</template>

<script lang="ts" setup>
import { useField, useForm } from 'vee-validate';
import { useAuthenticationStore } from '@/modules/authentication/store/authentication';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import { useI18n } from 'vue-i18n';
import { toTypedSchema } from '@vee-validate/zod';
import { string } from 'zod';
import { computed, ref } from 'vue';
import { AuthenticationSuccessfulResponse } from '@sdk/modules/authentication/objects/AuthenticationSuccessfulResponse';
import OrganismTwoFactorModal, {
  type OrganismTwoFactorModalEmitsPayloads
} from '@/modules/authentication/components/03_organisms/OrganismTwoFactorModal.vue';
import type { TwoFactorModalMessage } from '@/modules/authentication/interface/TwoFactorModalInterfaces';
import { AuthenticationErrorResponse } from '@sdk/modules/authentication/objects/AuthenticationErrorResponse';
import { Authentication2faResponse } from '@sdk/modules/authentication/objects/Authentication2faResponse';
import { MissingTwoFactorChallengeException } from '@sdk/modules/authentication/exceptions/MissingTwoFactorChallengeException';
import { InvalidTwoFactorChallengeException } from '@sdk/modules/authentication/exceptions/InvalidTwoFactorChallengeException';

definePage({
  meta: {
    isAuthPages: true,
    layout: 'auth'
  }
});

const { t, locale } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      error: {
        invalidTwoFactorChallengeResponse: 'Code d’authentification invalide.',
        missingTwoFactorResponseError:
          "Le code d'authentification deux factor est manquant.",
        'Wrong Credentials':
          'L’identifiant ou le mot de passe sont incorrects. Veuillez modifier les champs puis essayez de vous connecter de nouveau.'
      },
      login: 'Connexion',
      subtitle:
        'Entrez votre identifiant et votre mot de passe pour vous connecter à la plateforme.',
      username: 'Identifiant',
      usernamePlaceholder: 'Identifiant ou adresse courriel',
      password: 'Mot de passe',
      challengeCodeResent:
        'Un nouveau code a été envoyé à votre adresse courriel.',
      needHelp: {
        text: 'Besoin d’aide?',
        link: 'Visitez notre centre d’aide'
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      login: 'Login',
      subtitle: 'Enter your login and password to connect to the platform.',
      username: 'Username',
      usernamePlaceholder: 'Username or email address',
      password: 'Password',
      error: {
        invalidTwoFactorChallengeResponse: 'Invalid authentication code.',
        missingTwoFactorResponseError: 'The response is missing',
        'Wrong Credentials':
          'Username or password is incorrect. Please change the fields and try logging in again.'
      },
      challengeCodeResent: 'A new code has been sent to your email address.',
      needHelp: {
        text: 'Need help?',
        link: 'Visit our help center'
      }
    }
  }
});

const helpCenterUrl = computed(() => {
  let baseUrl = 'https://support.folkshr.com/hc/';
  baseUrl += locale.value === SUPPORTED_LANGUAGES.en ? 'en-ca' : 'fr-ca';
  return baseUrl;
});

const router = useRouter();
const authentificationStore = useAuthenticationStore();

const showPassword = ref(false);

const errorMessage = ref<string | null>(null);

const credentialsCache = ref({
  email: '',
  password: ''
});
const twoFactorModalVisible = ref<boolean>(false);

const twoFactorModalMessage = ref<TwoFactorModalMessage | undefined>();

const twoFactorModalLoadings = ref({
  submit: false,
  resend: false
});

const showTwoFactorModal = (email: string, password: string) => {
  credentialsCache.value = {
    email,
    password
  };

  twoFactorModalVisible.value = true;
};

const handleTwoFactorModalDismiss = async (
  value: OrganismTwoFactorModalEmitsPayloads['dismiss']
) => {
  try {
    if (value.action === 'cancel') {
      twoFactorModalVisible.value = false;
      return;
    }

    const twoFactorAuthenticationResponse = await respondToTwoFactorChallenge(
      credentialsCache.value.email,
      credentialsCache.value.password,
      value
    );

    if (
      twoFactorAuthenticationResponse instanceof
      AuthenticationSuccessfulResponse
    ) {
      twoFactorModalVisible.value = false;
      await completeLoginRequest(twoFactorAuthenticationResponse);
    }
  } catch (error) {
    resetLoaders();
    twoFactorModalMessage.value = {
      type: 'error',
      content: getErrorMessageFromError(error)
    };
  }
};

// Form Handling
const { handleSubmit, isSubmitting } = useForm();
const email = useField(
  'email',
  toTypedSchema(string().min(1, 'required').email())
);
const password = useField(
  'password',
  toTypedSchema(string().min(1, 'required'))
);

const respondToTwoFactorChallenge = async (
  email: string,
  password: string,
  twoFactorModalResponse: OrganismTwoFactorModalEmitsPayloads['dismiss']
) => {
  let twoFactorAuthenticationResponse;
  if (twoFactorModalResponse.action === 'submit') {
    twoFactorModalLoadings.value.submit = true;

    twoFactorAuthenticationResponse = await authentificationStore.login(
      email,
      password,
      twoFactorModalResponse.value
    );

    twoFactorModalLoadings.value.submit = false;
  } else if (twoFactorModalResponse.action === 'resend') {
    twoFactorModalLoadings.value.resend = true;

    twoFactorAuthenticationResponse = await authentificationStore.resend(
      email,
      password
    );

    twoFactorModalLoadings.value.resend = false;

    twoFactorModalMessage.value = {
      type: 'success',
      content: t('challengeCodeResent')
    };
  }

  return twoFactorAuthenticationResponse;
};

const completeLoginRequest = async (
  authenticationResponse: AuthenticationSuccessfulResponse
) => {
  credentialsCache.value = {
    email: '',
    password: ''
  };

  await authentificationStore.saveCredentials(
    authenticationResponse.getPayload()
  );
  await router.push({ name: '/' });

  return;
};

const handleAuthenticationResponse = async (
  email: string,
  password: string
) => {
  const authenticationCode = null;
  try {
    const authenticationResponse = await authentificationStore.login(
      email,
      password,
      authenticationCode
    );

    if (authenticationResponse instanceof AuthenticationSuccessfulResponse) {
      return completeLoginRequest(authenticationResponse);
    }

    if (authenticationResponse instanceof Authentication2faResponse) {
      return showTwoFactorModal(email, password);
    }
  } catch (error) {
    if (error instanceof MissingTwoFactorChallengeException) {
      if (!twoFactorModalVisible.value) {
        return showTwoFactorModal(email, password);
      } else {
        twoFactorModalMessage.value = {
          type: 'error',
          content: t('missingTwoFactorResponseError')
        };
      }
    } else {
      throw error;
    }
  }
};

const onSubmit = handleSubmit(async ({ email, password }) => {
  try {
    await handleAuthenticationResponse(email, password);
  } catch (error) {
    errorMessage.value = getErrorMessageFromError(error);
  }
});

const getErrorMessageFromError = (
  error: AuthenticationErrorResponse | Error | unknown
) => {
  if (error instanceof InvalidTwoFactorChallengeException) {
    return t('error.invalidTwoFactorChallengeResponse');
  } else if (error instanceof AuthenticationErrorResponse) {
    return t(`error.${error.getPayload().message}`);
  } else if (error instanceof Error) {
    return (error as Error).message;
  } else {
    return error as string;
  }
};

const resetLoaders = () => {
  twoFactorModalLoadings.value = {
    resend: false,
    submit: false
  };
};
</script>
