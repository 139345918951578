import type { Program } from '@/mapping/index.types';
import { LEGACY_TYPE } from '@/constants/legacyType';
import { BASE_ROUTE_TIMESHEETS_CONFIG } from '@/modules/timesheets/constants/urls';

const TIMESHEETS_PROGRAMS = [
  {
    name: 'timesheet_entries',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 250000,
    module: 'timesheets',
    routeName: '/time/timesheets',
    iframeUrl: '/legacy/old/timesheets'
  },
  {
    name: 'timesheet:config:legend',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505085,
    module: 'timesheets',
    routeName: `${BASE_ROUTE_TIMESHEETS_CONFIG}legend`,
    iframeUrl: '/legacy/old/timesheets/configurations/legend'
  },
  {
    name: 'timesheet:config:calculations',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505084,
    module: 'timesheets',
    routeName: `${BASE_ROUTE_TIMESHEETS_CONFIG}calculations`,
    iframeUrl: '/legacy/old/timesheets/configurations/calculations'
  },
  {
    name: 'timesheet:config:holidays',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505087,
    module: 'timesheets',
    routeName: `${BASE_ROUTE_TIMESHEETS_CONFIG}holidays`,
    iframeUrl: '/legacy/old/timesheets/configurations/holidays'
  },
  {
    name: 'timesheet:config:projects',
    isLegacy: LEGACY_TYPE.LEGACY_MONOLITH,
    programId: 505088,
    module: 'timesheets',
    routeName: `${BASE_ROUTE_TIMESHEETS_CONFIG}projects`,
    iframeUrl: '/legacy/old/timesheets/configurations/projects'
  }
] as Program[];

export default TIMESHEETS_PROGRAMS;
