/**
 * @description Project Settings
 */

const SETTINGS = {
  lintOnSave: false,
  mockApiBaseUrl: import.meta.env.VITE_APP_MOCK_API_BASE_URL,
  apiBaseURL: import.meta.env.VITE_APP_API_URL_ORIGIN + '/api/',
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  legacyOriginURL: import.meta.env.VITE_APP_LEGACY_URL_ORIGIN,

  supportEmail: import.meta.env.VITE_APP_SUPPORT_EMAIL ?? 'support@folkshr.com',

  title: 'Folks SIRH',
  titleSeparator: ' | ',
  titleReverse: true,
  // Shorthand
  abbreviation: 'flks',
  API_VERSION: import.meta.env.VITE_APP_API_VERSION,
  copyright: 'Folks',
  // Maximum number of cache routes
  keepAliveMaxNum: 99,
  // Route without token verification
  routesWhiteList: ['/login', '/register', '/callback', '/404', '/403'],
  //token name
  tokenName: 'access_token',
  //Name of the token key store in localStorage、sessionStoragecookie
  tokenTableName: 'accessToken',
  refreshTokenName: 'refreshToken',
  //token storage localtion localStorage sessionStorage cookie
  storage: 'localStorage',
  //Language type fr, en
  i18n: ['fr', 'en'],
  // Under which environment the highlighting error is displayed
  errorLog: ['development', 'staging'],
  //Modules that need to be automatically injected and loaded
  providePlugin: {},
  //Whether to automatically generate 7z compressed package during build
  build7z: false,
  // the default normal work hours of an employee.
  baseNormalWorkHours: 8,
  // amount of seconds the data is allowed to live inside the store before we refresh it.
  storeDataCacheLifetime: 60 * 5,

  postMessageAuthorizedOrigins: {
    folks_FE: import.meta.env.VITE_APP_V2_URL_ORIGIN,
    folks_LEGACY: import.meta.env.VITE_APP_LEGACY_URL_ORIGIN
  }
};
export default SETTINGS;
