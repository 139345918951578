import { defineStore } from 'pinia';
import type { Permission } from '@/modules/core/stores/permissions.types';
import { defineAbility } from '@casl/ability';
import { provideAbility, useAbility } from '@casl/vue';

export const usePermissionsStore = defineStore('permissions', {
  state: (): {
    permissions: Permission[];
  } => ({
    permissions: []
  }),
  getters: {
    can: () => {
      return (action: string, subject: string) => {
        const { can } = useAbility();
        return can(action, subject);
      };
    }
  },
  actions: {
    setPermissions(permissions: Permission[]) {
      this.permissions = permissions;
      const abilityProvider = defineAbility((can) => {
        this.permissions.forEach((permission) => {
          can(permission.action, permission.subject);
        });
      });
      provideAbility(abilityProvider);
    }
  }
});
