<template>
  <LegacyIframe :iframe-route="$route.path" />
</template>
<script lang="ts" setup>
definePage({
  meta: {
    needAuth: true
  }
});
</script>
