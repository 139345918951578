<template>
  <VNavigationDrawer
    :rail="rail"
    permanent
    @click="rail = false"
  >
    <div class="d-flex flex-column justify-space-between fill-height2 pt-2">
      <div>
        <div class="d-flex flex-column px-1">
          <VTooltip>
            <template #activator="{ props }">
              <VBtn
                v-dynamic-data-attr="{ all: 'button-open-navigation-sidebar' }"
                :icon="railIcon"
                class="mb-1"
                rounded
                v-bind="props"
                variant="text"
                @click.stop="toggleRail"
              >
              </VBtn>
            </template>
            {{ railTooltipText }}
          </VTooltip>
          <VTooltip>
            <template #activator="{ props }">
              <VBtn
                v-dynamic-data-attr="{ all: `button-menu-home` }"
                :icon="'md:home'"
                color="tertiary"
                rounded
                to="/"
                v-bind="props"
                variant="text"
                @click.stop
              >
              </VBtn>
            </template>
            {{ t('home') }}
          </VTooltip>
        </div>
        <VList
          v-if="settings"
          :nav="true"
        >
          <VListItem
            v-for="item in settingsMenuItems"
            :key="item.name"
            v-dynamic-data-attr="{ all: `button-menu-${item.name}` }"
            class="text-no-wrap"
            v-bind="getListItemData(item)"
          >
            <template #prepend>
              <VIcon v-dynamic-data-attr="{ all: `icon-menu-${item.name}` }">
                {{ item.icon }}
              </VIcon>
            </template>
            {{ t(`navigation.${item.name}`) }}
          </VListItem>
        </VList>
        <VList
          v-else
          :nav="true"
          :opened="areDrawersOpen ? currentlyOpen : []"
        >
          <template
            v-for="item in menuItems"
            :key="item.name"
          >
            <VListGroup v-if="item.children">
              <template #activator="{ props: listGroupProps }">
                <VListItem
                  v-dynamic-data-attr="{ all: `menu-${item.name}` }"
                  rounded="x1"
                  v-bind="listGroupProps"
                  @click.stop="rail = false"
                >
                  <template #prepend>
                    <VIcon>{{ item.icon }}</VIcon>
                  </template>
                  {{ t(`navigation.${item.name}`) }}
                </VListItem>
              </template>
              <VListItem
                v-for="child in item.children"
                :key="child.name"
                v-dynamic-data-attr="{ all: `menu-${item.name}-${child.name}` }"
                class="text-no-wrap"
                v-bind="getListItemData(child)"
              >
                {{ t(`navigation.${child.name}`) }}
                <template #append>
                  <VIcon
                    v-if="child.href && child.icon"
                    v-dynamic-data-attr="{
                      all: `icon-menu-${item.name}-${child.name}`
                    }"
                    end
                    size="small"
                    target="_blank"
                  >
                    md:open_in_new
                  </VIcon>
                </template>
              </VListItem>
            </VListGroup>

            <VListItem
              v-else
              class="text-no-wrap"
              nav
              v-bind="getListItemData(item)"
            >
              <template #prepend>
                <VIcon v-dynamic-data-attr="{ all: `icon-menu-${item.name}` }">
                  {{ item.icon }}
                </VIcon>
              </template>
              {{ t(`navigation.${item.name}`) }}
            </VListItem>
            <VDivider
              v-if="item.sectionEnd"
              class="my-3 pl-n10"
              thickness="2"
            ></VDivider>
          </template>
        </VList>
      </div>
      <VList :nav="true">
        <VListItem
          v-if="settings"
          v-dynamic-data-attr="{ all: `menu-setting-item` }"
          class="text-no-wrap"
          nav
          v-bind="getListItemData(myPortal)"
        >
          <template #prepend>
            <VIcon v-dynamic-data-attr="{ all: `icon-menu-my-portal` }">
              {{ myPortal.icon }}
            </VIcon>
          </template>
          {{ t(`navigation.${myPortal.name}`) }}
        </VListItem>
        <VDivider
          class="my-3 pl-n10"
          thickness="2"
        ></VDivider>
        <VTooltip>
          <template #activator="{ props }">
            <VListItem
              v-dynamic-data-attr="{ all: `menu-settings` }"
              class="mb-2 text-no-wrap"
              @click="settingsToggle"
            >
              <template #prepend>
                <VIcon
                  v-dynamic-data-attr="{ all: `icon-menu-settings` }"
                  v-bind="props"
                >
                  {{ settings ? 'md:arrow_back' : 'md:settings' }}
                </VIcon>
              </template>
              {{ settings ? t('back') : t('settings') }}
            </VListItem>
          </template>
          {{ settingsTooltipText }}
        </VTooltip>
      </VList>
    </div>
  </VNavigationDrawer>
</template>

<script lang="ts" setup>
import { type Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { SUPPORTED_LANGUAGES } from '@/constants/languages';
import englishTranslations from '@/locales/app-nav-menu/en-US.json';
import frenchTranslations from '@/locales/app-nav-menu/fr-CA.json';
import type { ListItem, MenuItem } from '@/components/AppNavigationRail.types';
import { useNavigationRailStore } from '@/stores/navigationRail';
import { storeToRefs } from 'pinia';
import { useDisplay } from 'vuetify';
import { BASE_ROUTE_VACATIONS } from '@/modules/vacations/constants/urls';
import { BASE_ROUTE_ABSENCES } from '@/modules/absences/constants/urls';
import { BASE_ROUTE_OVERTIMES } from '@/modules/overtimes/constants/urls';
import { BASE_ROUTE_TIMESHEETS } from '@/modules/timesheets/constants/urls';

const { t } = useI18n({
  messages: {
    [SUPPORTED_LANGUAGES.fr]: {
      open: 'Ouvrir',
      close: 'Fermer',
      back: 'Retour',
      settings: 'Paramètres',
      home: 'Accueil',
      navigation: {
        company: 'Compagnie',
        norms: 'Normes',
        ...frenchTranslations
      }
    },
    [SUPPORTED_LANGUAGES.en]: {
      open: 'Open',
      close: 'Close',
      back: 'Back',
      settings: 'Settings',
      home: 'Home',
      navigation: {
        company: 'Company',
        norms: 'Standards',
        ...englishTranslations
      }
    }
  }
});

const { name } = useDisplay();
watch(name, (value) => {
  if (['xs', 'sm'].includes(value)) {
    rail.value = true;
  }
});

const { rail } = storeToRefs(useNavigationRailStore());
const railIcon = computed(() => (rail.value ? 'md:menu' : 'md:menu_open'));
const toggleRail = () => {
  rail.value = !rail.value;
};
let railTooltipText = ref();
watch(
  rail,
  (value) => {
    railTooltipText.value = value ? t('open') : t('close');
  },
  { immediate: true }
);

const settings = ref(false);
const settingsToggle = () => {
  settings.value = !settings.value;
};
let settingsTooltipText = ref();
watch(
  settings,
  (value) => {
    settingsTooltipText.value = value ? t('back') : t('settings');
  },
  { immediate: true }
);

const areDrawersOpen = ref(false);
const currentlyOpen = ref([]);
watch(rail, (value) => {
  if (value) {
    areDrawersOpen.value = false;
  }
});

const myPortal: Ref<MenuItem> = ref({
  subject: 'request',
  icon: 'md:settings_applications',
  name: 'system_settings',
  sectionEnd: true,
  to: '/myPortal/'
});

const menuItems = ref([
  {
    name: 'time_management',
    icon: 'md:schedule',
    children: [
      {
        name: 'absences',
        to: `${BASE_ROUTE_ABSENCES}`
      },
      {
        name: 'vacation',
        to: `${BASE_ROUTE_VACATIONS}`
      },
      {
        name: 'overtime',
        to: `${BASE_ROUTE_OVERTIMES}`
      },
      {
        name: 'timesheet',
        to: `${BASE_ROUTE_TIMESHEETS}`
      }
    ]
  },
  {
    name: 'company',
    icon: 'md:business',
    children: [
      {
        name: 'norms',
        to: '/company/standards'
      }
    ]
  }
]) as Ref<MenuItem[]>;

const settingsMenuItems = ref([]) as Ref<MenuItem[]>;

const getListItemData = (item: MenuItem) => {
  const listItem: ListItem = {
    value: item.name,
    rounded: 'x1'
  };

  if (item.href) {
    listItem.href = item.href;
  } else if (item.to) {
    listItem.to = item.to;
  }

  return listItem;
};
</script>

<style scoped>
.fill-height2 {
  min-height: 100%;
}
</style>
