import HTTP_STATUS_CODES from '@/modules/core/constants/httpStatusCodes';
import {
  refreshToken,
  retryCurrentRequest
} from '@/modules/core/utils/apiFolks/refreshToken';

export const handleErrorCode = (
  status: number,
  message: string,
  axiosResponseOrError: any,
  responseData: any
) => {
  switch (status) {
    case HTTP_STATUS_CODES.Unauthorized:
      return refreshToken()
        .then(() => {
          return retryCurrentRequest(axiosResponseOrError);
        })
        .catch((err) =>
          Promise.reject('Could not refresh token: ' + err.message)
        );
    case HTTP_STATUS_CODES.Forbidden:
    case HTTP_STATUS_CODES.NotFound:
      return Promise.reject(axiosResponseOrError);
    case HTTP_STATUS_CODES.UnprocessableEntity:
      return Promise.reject(
        Object.values(responseData.errors).map((propertyMessage: any) =>
          propertyMessage.map((item: string) => item).join(', ')
        )
      );
    case HTTP_STATUS_CODES.BadRequest:
      return Promise.reject(responseData.message);
    default:
      if (import.meta.env.DEV) {
        console.log(message || `Unexpected error: ${status}`);
      }
      return Promise.reject(axiosResponseOrError);
  }
};
